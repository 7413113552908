<script>
import Layout from "@/router/layouts/exchange";
import { VclList } from "vue-content-loading";

export default {
  locales: {
    pt: {
      'Bank Withdrawals': 'Saques',
      'Withdraw': 'Sacar',
      'Identifier': 'Identificador',
      'Sent in': 'Enviado em',
      'Destiny': 'Destino',
      'Value': 'Valor',
      'Rate': 'Taxa',
      'Receiving': 'A receber',
      'Status': 'Estado',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'Bank Withdrawals': 'Saques',
      'Withdraw': 'Sacar',
      'Identifier': 'Identificador',
      'Sent in': 'Enviado em',
      'Destiny': 'Destino',
      'Value': 'Valor',
      'Rate': 'Taxa',
      'Receiving': 'A receber',
      'Status': 'Estado',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: { Layout, VclList },
  data() {
    return {
      filter: {
        status: {
          value: 'all',
          options: {
            all: 'All',
            pending: 'Pending',
            confirmed: 'Confirmed',
            refused: 'Refused',
            canceled: 'Canceled',
          }
        }
      },
      table: {
        heade: [
          'Identifier', 'Sent in', 'Destiny', 'Value', 'Rate', 'Receiving', 'Status'
        ],
        body: [
          { identifier: '1as4444', send: 1.111, destiny: 35, value: 20, rate: '00', receiving: 100, state: 'Executado integralmente' },
          { identifier: '1as4444', send: 1.111, destiny: 35, value: 20, rate: '00', receiving: 100, state: 'Executado integralmente' },
          { identifier: '1as4444', send: 1.111, destiny: 35, value: 20, rate: '00', receiving: 100, state: 'Executado integralmente' },
          { identifier: '1as4444', send: 1.111, destiny: 35, value: 20, rate: '00', receiving: 100, state: 'Executado integralmente' },
        ],
        loading: false,
        errored: false,
        empty: null,
        modal: false,
      },
      statData: null
    };
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0">{{ t('Bank Withdrawals') }}</h4>
          <button class="btn btn-success" @click="table.modal = true">{{ t('Withdraw') }}</button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body d-flex">
            <div class="mr-3">
              <b-input-group :prepend="t('Status')">
                <b-form-select v-model="filter.status.value" :options="filter.status.options"></b-form-select>
              </b-input-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="table.empty">{{ t('No records found.') }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-centered table-nowrap table-striped">
                <thead class="thead-light">
                  <tr>
                    <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td,index) in table.body" :key="index">
                    <td>{{ td.identifier }}</td>
                    <td>{{ td.send }}</td>
                    <td>{{ td.destiny }}</td>
                    <td>{{ td.value }}</td>
                    <td>{{ td.rate }}</td>
                    <td>{{ td.receiving }}</td>
                    <td>{{ td.status }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="table.modal" :title="t('Bitcoin')" centered>
    </b-modal>
  </Layout>
</template>